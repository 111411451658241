import { validate } from 'bitcoin-address-validation';
import { isAddress } from '@ethersproject/address';
import { base58_to_binary } from 'base58-js';

import { BigNumber } from 'bignumber.js';

export function validateAddress(addr: string, network: string): boolean {
  switch (network) {
    case 'bitcoin':
      return validate(addr);
    case 'ethereum':
    case 'avalanche':
    case 'polygon':
      return isAddress(addr);
    case 'stellar':
      const regexXLM = /^G[A-Z2-7]{55}$/;
      return regexXLM.test(addr);
    case 'polymesh':
      try {
        return Boolean(base58_to_binary(addr));
      } catch (e) {
        return false;
      }
    case 'solana':
      return validateSolanaAddress(addr);
    case 'ripple':
      const regexXRP = /^r[1-9A-HJ-NP-Za-km-z]{24,34}$/;
      return regexXRP.test(addr);  
    default:
      return false;
  }
}

function validateSolanaAddress(addr: string): boolean {
  return new RegExp(/^[1-9A-HJ-NP-Za-km-z]{32,44}$/).test(addr);
}

// TODO: this function is serving more than one purpose in an unintuitive way
export function formatNetwork(s: string): string {
  switch (s.toLowerCase()) {
    case 'btc':
      return 'bitcoin';
    case 'eth':
      return 'ethereum';
    case 'polyx':
      return 'polymesh';
    case 'xlm':
      return 'stellar';
    case 'avax':
      return 'avalanche';
    case 'sol':
      return 'solana';
    case 'xrp':
      return 'ripple';
    case 'bitcoin':
      return 'BTC';
    case 'ethereum':
      return 'ETH';
    case 'polymesh':
      return 'POLYX';
    case 'stellar':
      return 'XLM';
    case 'avalanche':
      return 'AVAX';
    case 'solana':
      return 'SOL';
    case 'ripple':
      return 'XRP';
    default:
      return '';
  }
}

export function conversionFactor(asset: string, fractionDigits: number): number {
  switch (asset.toLowerCase()) {
    case 'eth':
    case 'avax':
      return 1e-18;
    case 'btc':
    case 'ltc':
      return 1e-8;
    case 'polyx':
      return 1e-6;
    case 'xlm':
      return 1e-7;
    case 'xrp':
      return 1e-6;  
    default:
      if (fractionDigits) {
        return BigNumber(10).pow(-fractionDigits).toNumber();
      }
      return 1;
  }
}

export function nativeTokenByNetwork(network: string): string {
  switch (network) {
    case 'bitcoin':
      return 'BTC';
    case 'ethereum':
      return 'ETH';
    case 'avalanche':
      return 'AVAX';
    case 'polymesh':
      return 'POLYX';
    case 'stellar':
      return 'XLM';
    case 'solana':
      return 'SOL';
    case 'ripple':
      return 'XRP';
    case 'polygon':
      return 'MATIC';
    default:
      return '';
  }
}

/**
 * @description - Format an integer amount to a decimal amount in base units; e.g. 5000 Satoshi to 0.00005 BTC.
 * @param amount - The amount, given as an integer
 * @param asset - The asset, e.g. 'btc' or 'eth'
 * @param fractionDigits - The number of digits expected after the decimal. Set to 0 for full precision (default).
 */
export function formatIntegerAmount(
  amount: string | number,
  asset: string,
  fractionDigits: number = 0,
): string {
  const res = BigNumber(amount).times(conversionFactor(asset, fractionDigits));

  if (fractionDigits <= 0) {
    return res.toString();
  }
  return res.toFormat(fractionDigits);
}
