import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { useState } from 'react';
import { NetworkLogo } from '../../../assets/networks';
import { nativeTokenByNetwork } from '../../../utils/networkTools';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';

export const ASSET_CHUNK_SIZE = 4;

export default function WalletListItem({ data, category, query }) {
  const navigate = useNavigate();

  const accounts = data?.accounts ?? {};
  const assets = Object.keys(accounts).reduce((accum, network) => {
    const newAssets = accounts[network]?.assets ?? [];
    accum.push(...newAssets.map(asset => ({ asset, network })));
    return accum;
  }, []);
  //   (accum, network) => [
  //     ...accum,
  //     ...accounts[network]?.assets?.map(asset => ({
  //       asset,
  //       network,
  //     })),
  //   ],
  //   [],
  // );

  if (!data || !category) {
    return null;
  }

  return (
    <tr
      onClick={() => navigate(`/dashboard/wallets/${data.walletId}`)}
      className="h-10 text-xs cursor-pointer leading-none text-white hover:bg-[#FFFFFF10] border-b border-t border-dotted border-gray-400"
    >
      <td className={data.active ? 'text-white' : '!text-red-500'}>
        <Highlighter
          highlightClassName={'HighlightActive'}
          textToHighlight={data.walletId}
          searchWords={[query]}
          caseSensitive={false}
          autoEscape={true}
        />
      </td>
      <td className={data.active ? 'text-white' : '!text-red-500'}>
        <Highlighter
          highlightClassName="HighlightActive"
          textToHighlight={data.owner}
          searchWords={[query]}
          caseSensitive={false}
          autoEscape={true}
        />
      </td>
      <td>
        <div>
          {data.managers.slice(0, 2).map((email, i) => (
            <div key={i}>{email}</div>
          ))}
        </div>
        <ExtraManagers data={data} />
      </td>
      <td onClick={evt => evt.stopPropagation()}>
        <TokensList assets={assets} />
      </td>
    </tr>
  );
}

/**
 * @param {any[]} assets
 * @returns {JSX.Element}
 * @constructor
 */
function TokensList({ assets }) {
  const [show, setShow] = useState(false);

  if (!show) {
    return (
      <div className="flex justify-end space-x-1">
        {assets.slice(0, ASSET_CHUNK_SIZE).map(({ network, asset }) => (
          <div className="w-6" key={asset}>
            <NetworkLogo network={nativeTokenByNetwork(network)} token={asset} size="s" />
          </div>
        ))}
        <span
          className={`flex space-x-1 text-xs min-w-fit p-1 bg-fuchsia-600 hover:bg-fuchsia-700 rounded-md ${assets.length <= ASSET_CHUNK_SIZE && 'hidden'}`}
          onClick={() => setShow(prev => !prev)}
        >
          <PlusIcon className="w-3" />
          More
        </span>
      </div>
    );
  }

  const walletDataChunks = [];
  for (let i = 0; i * ASSET_CHUNK_SIZE < assets.length; i++) {
    const chunk = assets.slice(i * ASSET_CHUNK_SIZE, i * ASSET_CHUNK_SIZE + ASSET_CHUNK_SIZE);
    walletDataChunks.push(chunk);
  }

  return (
    <div className="flex justify-end">
      <div className="flex flex-col space-y-1">
        {walletDataChunks.map((chunk, iChunk) => (
          <span className="flex items-center justify-end space-x-1" key={iChunk}>
            {chunk.map(({ network, asset }, iAsset) => (
              <div className="w-6" key={iAsset}>
                <NetworkLogo network={nativeTokenByNetwork(network)} token={asset} size="s" />
              </div>
            ))}
          </span>
        ))}
      </div>
      <div className="flex flex-col p-1">
        <span
          className="flex items-center space-x-1 text-xs p-1 bg-fuchsia-600 hover:bg-fuchsia-700 rounded-md"
          onClick={() => setShow(prev => !prev)}
        >
          <MinusIcon className="w-3" />
          Less
        </span>
      </div>
    </div>
  );
}

/**
 * @param {any} data
 * @returns {JSX.Element | null}
 * @constructor
 */
function ExtraManagers({ data }) {
  const defaultShowCount = 2;
  const [hover, setHover] = useState(false);
  const extraManagersExist = Boolean(data?.managers?.length > defaultShowCount);

  if (!extraManagersExist) {
    return null;
  }

  return (
    <div>
      <span
        className="cursor-pointer text-[#22c55e] underline"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        and {data.managers.length - defaultShowCount} more
      </span>
      <div
        className={`rounded absolute left-3/4 -translate-x-1/2 bg-white text-black p-5 ${hover && extraManagersExist ? '' : 'hidden'}`}
      >
        <ul>
          {data.managers.slice(defaultShowCount).map((email, i) => (
            <li key={i}>{email}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
